<template>
    <v-container fluid style="padding-bottom:200px">
        <v-row>
            <v-col cols="12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </v-col>
        </v-row>
        <v-row class="ml-4 mr-5">
            <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                <v-layout align-center pr-4>
                <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                <v-layout column>
                    <div>
                    <strong>{{ snackbar.title }}</strong>
                    </div>
                    <div>{{ snackbar.text }}</div>
                </v-layout>
                </v-layout>
                <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                <v-icon>clear</v-icon>
                </v-btn>
            </v-snackbar>
            <v-col cols="12">
                <v-card outlined>
                    <v-container fluid class="p-3">
                        <v-row>
                            <v-col xs="12" sm="6" md="3" class="col-12">
                                <h6 class="blue-lcd" style="font-weight:600">Mill ID</h6>
                                <v-autocomplete
                                    solo
                                    dense
                                    clearable
                                    v-model="mill"
                                    :items="mills"
                                    item-value="mill_id"
                                    item-text="mill_name"
                                    label="MILL ID"
                                    class="border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col xs="12" sm="6" md="2" class="col-12">
                                <h6 class="blue-lcd" style="font-weight:600">Quality ID</h6>
                                <v-autocomplete
                                    solo
                                    dense
                                    clearable
                                    v-model="quality_id"
                                    :items="qualities"
                                    item-value="quality_id"
                                    item-text="quality_id"
                                    label="Quality ID"
                                    class="border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col xs="12" sm="3" md="3" class="col-12">
                                <h6 class="blue-lcd" style="font-weight:600">Period</h6>
                                <v-menu
                                    ref="menu"
                                    v-model="menu"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        solo
                                        dense
                                        v-model="date"
                                        label="Period"
                                        persistent-hint
                                        append-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="date"
                                        type="month"
                                        no-title
                                        @input="menu = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col class="col-12" xs="12" sm="6" md="4">
                                <v-btn class="border-12 text-white p-3 mt-7" color="primary" @click="search(1,20)">Search</v-btn>
                                <v-btn class="border-12 text-white p-3 mt-7 ml-3" color="secondary" @click="clear()">Clear</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="mt-8" v-show="display">
            <v-col cols="12" style="padding-bottom: 100px">
                <v-card style="border-top: 3px solid #1565C0">
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-card-title>
                                    Result
                                    <v-spacer></v-spacer>
                                    <v-btn 
                                        class="pa-5"
                                        small
                                        color="primary"
                                        :disabled="disabledExport"
                                    >
                                        <download-excel
                                            :fetch           = "exportExcel"
                                            :fields="header_field"
                                            :before-generate = "startDownload"
                                            :before-finish   = "finishDownload">
                                            <v-icon>
                                                mdi-file-excel
                                            </v-icon>
                                            Export to Excel
                                        </download-excel>  
                                        
                                    </v-btn>
                                </v-card-title>
                                <v-card-text>
                                    <div class="table-responsive">
                                        <table class="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <td scope="col" rowspan="3" style="vertical-align: middle;text-align: center;">Bulan</td>
                                                    <td scope="col" colspan="5" style="text-align: center;">
                                                        Total
                                                    </td>
                                                    <td scope="col" colspan="5" style="text-align: center;">
                                                        Atap
                                                    </td>
                                                    <td scope="col" colspan="5" style="text-align: center;">
                                                        Truss
                                                    </td>
                                                    <td scope="col" colspan="5" style="text-align: center;">
                                                        Hollow
                                                    </td>
                                                    <td scope="col" colspan="5" style="text-align: center;">
                                                        Genteng
                                                    </td>
                                                    <td scope="col" colspan="5" style="text-align: center;">
                                                        Pintu
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td scope="col" colspan="2" style="text-align: center;">
                                                        Selisih Panjang Label VS Panjang Pakai
                                                    </td>
                                                    <td scope="col" colspan="3" style="text-align: center;">
                                                        Total Non Prime
                                                    </td>
                                                    <td scope="col" colspan="2" style="text-align: center;">
                                                        Selisih Panjang Label VS Panjang Pakai
                                                    </td>
                                                    <td scope="col" colspan="3" style="text-align: center;">
                                                        Total Non Prime
                                                    </td>
                                                    <td scope="col" colspan="2" style="text-align: center;">
                                                        Selisih Panjang Label VS Panjang Pakai
                                                    </td>
                                                    <td scope="col" colspan="3" style="text-align: center;">
                                                        Total Non Prime
                                                    </td>
                                                    <td scope="col" colspan="2" style="text-align: center;">
                                                        Selisih Panjang Label VS Panjang Pakai
                                                    </td>
                                                    <td scope="col" colspan="3" style="text-align: center;">
                                                        Total Non Prime
                                                    </td>
                                                    <td scope="col" colspan="2" style="text-align: center;">
                                                        Selisih Panjang Label VS Panjang Pakai
                                                    </td>
                                                    <td scope="col" colspan="3" style="text-align: center;">
                                                        Total Non Prime
                                                    </td>
                                                    <td scope="col" colspan="2" style="text-align: center;">
                                                        Selisih Panjang Label VS Panjang Pakai
                                                    </td>
                                                    <td scope="col" colspan="3" style="text-align: center;">
                                                        Total Non Prime
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td scope="col" style="text-align: center;">Meter</td>
                                                    <td scope="col" style="text-align: center;">%</td>
                                                    <td scope="col" style="text-align: center;">Meter</td>
                                                    <td scope="col" style="text-align: center;">%</td>
                                                    <td scope="col" style="text-align: center;">Target</td>

                                                    <td scope="col" style="text-align: center;">Meter</td>
                                                    <td scope="col" style="text-align: center;">%</td>
                                                    <td scope="col" style="text-align: center;">Meter</td>
                                                    <td scope="col" style="text-align: center;">%</td>
                                                    <td scope="col" style="text-align: center;">Target</td>

                                                    <td scope="col" style="text-align: center;">Meter</td>
                                                    <td scope="col" style="text-align: center;">%</td>
                                                    <td scope="col" style="text-align: center;">Meter</td>
                                                    <td scope="col" style="text-align: center;">%</td>
                                                    <td scope="col" style="text-align: center;">Target</td>

                                                    <td scope="col" style="text-align: center;">Meter</td>
                                                    <td scope="col" style="text-align: center;">%</td>
                                                    <td scope="col" style="text-align: center;">Meter</td>
                                                    <td scope="col" style="text-align: center;">%</td>
                                                    <td scope="col" style="text-align: center;">Target</td>

                                                    <td scope="col" style="text-align: center;">Meter</td>
                                                    <td scope="col" style="text-align: center;">%</td>
                                                    <td scope="col" style="text-align: center;">Meter</td>
                                                    <td scope="col" style="text-align: center;">%</td>
                                                    <td scope="col" style="text-align: center;">Target</td>

                                                    <td scope="col" style="text-align: center;">Meter</td>
                                                    <td scope="col" style="text-align: center;">%</td>
                                                    <td scope="col" style="text-align: center;">Meter</td>
                                                    <td scope="col" style="text-align: center;">%</td>
                                                    <td scope="col" style="text-align: center;">Target</td>
                                                </tr>
                                            </thead>
                                            <tbody v-for="(item, index) in coilratios" :key="index">
                                                <tr class="table-active">
                                                    <td scope="col" colspan="31" style="font-weight: 600;">
                                                        {{item.mill_name}}
                                                    </td>
                                                </tr>
                                                <tr v-for="(data, i) in item.data" :key="i">
                                                    <td scope="col" style="text-align: left;">{{data.bulan}}</td>
                                                    <td scope="col" style="text-align: right;text-decoration:underline;cursor:pointer" @click="showDetail(data, 'ALL', 'PRIME', data.TOT_PRM)">
                                                        {{$store.getters.convertToCurrencyUs(data.TOT_PRM)}}
                                                    </td>
                                                    <td scope="col" style="text-align: right;text-decoration:underline;cursor:pointer" @click="showDetail(data, 'ALL', 'PRIME', data.RATIO_TOT_PRM)">
                                                        {{$store.getters.convertToCurrencyUs(data.RATIO_TOT_PRM)}}
                                                    </td>
                                                    <td scope="col" :class="data.RATIO_TOT_NON > data.TARGET_NON ? 'red--text' : 'green--text'" style="text-align: right;text-decoration:underline;cursor:pointer" @click="showDetail(data, 'ALL', 'NONE', data.TOT_NON)">
                                                        {{$store.getters.convertToCurrencyUs(data.TOT_NON)}}
                                                    </td>
                                                    <td scope="col" :class="data.RATIO_TOT_NON > data.TARGET_NON ? 'red--text' : 'green--text'" style="text-align: right;text-decoration:underline;cursor:pointer" @click="showDetail(data, 'ALL', 'NONE', data.RATIO_TOT_NON)">
                                                        {{$store.getters.convertToCurrencyUs(data.RATIO_TOT_NON)}}
                                                    </td>
                                                    <td scope="col" :class="data.RATIO_TOT_NON > data.TARGET_NON ? 'red--text' : 'green--text'" style="text-align: right;" @click="showDetail(data, 'ALL', 'NONE', data.TARGET_NON)">
                                                        {{$store.getters.convertToCurrencyUs(data.TARGET_NON)}}
                                                    </td>

                                                    <td scope="col" style="text-align: right;text-decoration:underline;cursor:pointer" @click="showDetail(data, 'ATAP', 'PRIME', data.ATAP_PRM)">
                                                        {{$store.getters.convertToCurrencyUs(data.ATAP_PRM)}}
                                                    </td>
                                                    <td scope="col" style="text-align: right;text-decoration:underline;cursor:pointer" @click="showDetail(data, 'ATAP', 'PRIME', data.RATIO_ATAP_PRM)">
                                                        {{$store.getters.convertToCurrencyUs(data.RATIO_ATAP_PRM)}}
                                                    </td>
                                                    <td scope="col" :class="data.RATIO_ATAP_NON > data.TARGET_ATAP_NON ? 'red--text' : 'green--text'" style="text-align: right;text-decoration:underline;cursor:pointer" @click="showDetail(data, 'ATAP', 'NONE', data.ATAP_NON)">
                                                        {{$store.getters.convertToCurrencyUs(data.ATAP_NON)}}
                                                    </td>
                                                    <td scope="col" :class="data.RATIO_ATAP_NON > data.TARGET_ATAP_NON ? 'red--text' : 'green--text'" style="text-align: right;text-decoration:underline;cursor:pointer" @click="showDetail(data, 'ATAP', 'NONE', data.RATIO_ATAP_NON)">
                                                        {{$store.getters.convertToCurrencyUs(data.RATIO_ATAP_NON)}}
                                                    </td>
                                                    <td scope="col" :class="data.RATIO_ATAP_NON > data.TARGET_ATAP_NON ? 'red--text' : 'green--text'" style="text-align: right;" @click="showDetail(data, 'ATAP', 'NONE', data.TARGET_ATAP_NON)">
                                                        {{$store.getters.convertToCurrencyUs(data.TARGET_ATAP_NON)}}
                                                    </td>

                                                    <td scope="col" style="text-align: right;text-decoration:underline;cursor:pointer" @click="showDetail(data, 'TRUSS', 'PRIME', data.TRUSS_PRM)">
                                                        {{$store.getters.convertToCurrencyUs(data.TRUSS_PRM)}}
                                                    </td>
                                                    <td scope="col" style="text-align: right;text-decoration:underline;cursor:pointer" @click="showDetail(data, 'TRUSS', 'PRIME', data.RATIO_TRUSS_PRM)">
                                                        {{$store.getters.convertToCurrencyUs(data.RATIO_TRUSS_PRM)}}
                                                    </td>
                                                    <td scope="col" :class="data.RATIO_TRUSS_NON > data.TARGET_TRUSS_NON ? 'red--text' : 'green--text'" style="text-align: right;text-decoration:underline;cursor:pointer" @click="showDetail(data, 'TRUSS', 'NONE', data.TRUSS_NON)">
                                                        {{$store.getters.convertToCurrencyUs(data.TRUSS_NON)}}
                                                    </td>
                                                    <td scope="col" :class="data.RATIO_TRUSS_NON > data.TARGET_TRUSS_NON ? 'red--text' : 'green--text'" style="text-align: right;text-decoration:underline;cursor:pointer" @click="showDetail(data, 'TRUSS', 'NONE', data.RATIO_TRUSS_NON)">
                                                        {{$store.getters.convertToCurrencyUs(data.RATIO_TRUSS_NON)}}
                                                    </td>
                                                    <td scope="col" :class="data.RATIO_TRUSS_NON > data.TARGET_TRUSS_NON ? 'red--text' : 'green--text'" style="text-align: right;" @click="showDetail(data, 'TRUSS', 'NONE', data.TARGET_TRUSS_NON)">
                                                        {{$store.getters.convertToCurrencyUs(data.TARGET_TRUSS_NON)}}
                                                    </td>                                                    

                                                    <td scope="col" style="text-align: right;text-decoration:underline;cursor:pointer" @click="showDetail(data, 'HOLLOW', 'PRIME', data.HOLLOW_PRM)">
                                                        {{$store.getters.convertToCurrencyUs(data.HOLLOW_PRM)}}
                                                    </td>
                                                    <td scope="col" style="text-align: right;text-decoration:underline;cursor:pointer" @click="showDetail(data, 'HOLLOW', 'PRIME', data.RATIO_HOLLOW_PRM)">
                                                        {{$store.getters.convertToCurrencyUs(data.RATIO_HOLLOW_PRM)}}
                                                    </td>
                                                    <td scope="col" :class="data.RATIO_HOLLOW_NON > data.TARGET_HOLLOW_NON ? 'red--text' : 'green--text'" style="text-align: right;text-decoration:underline;cursor:pointer" @click="showDetail(data, 'HOLLOW', 'NONE', data.HOLLOW_NON)">
                                                        {{$store.getters.convertToCurrencyUs(data.HOLLOW_NON)}}
                                                    </td>
                                                    <td scope="col" :class="data.RATIO_HOLLOW_NON > data.TARGET_HOLLOW_NON ? 'red--text' : 'green--text'" style="text-align: right;text-decoration:underline;cursor:pointer" @click="showDetail(data, 'HOLLOW', 'NONE', data.RATIO_HOLLOW_NON)">
                                                        {{$store.getters.convertToCurrencyUs(data.RATIO_HOLLOW_NON)}}
                                                    </td>
                                                    <td scope="col" :class="data.RATIO_HOLLOW_NON > data.TARGET_HOLLOW_NON ? 'red--text' : 'green--text'" style="text-align: right;" @click="showDetail(data, 'HOLLOW', 'NONE', data.TARGET_HOLLOW_NON)">
                                                        {{$store.getters.convertToCurrencyUs(data.TARGET_HOLLOW_NON)}}
                                                    </td>
                                                    

                                                    <td scope="col" style="text-align: right;text-decoration:underline;cursor:pointer" @click="showDetail(data, 'GENTING', 'PRIME', data.GENTING_PRM)">
                                                        {{$store.getters.convertToCurrencyUs(data.GENTING_PRM)}}
                                                    </td>
                                                    <td scope="col" style="text-align: right;text-decoration:underline;cursor:pointer" @click="showDetail(data, 'GENTING', 'PRIME', data.RATIO_GENTING_PRM)">
                                                        {{$store.getters.convertToCurrencyUs(data.RATIO_GENTING_PRM)}}
                                                    </td>
                                                    <td scope="col" :class="data.RATIO_GENTING_NON > data.TARGET_GENTING_NON ? 'red--text' : 'green--text'" style="text-align: right;text-decoration:underline;cursor:pointer" @click="showDetail(data, 'GENTING', 'NONE', data.GENTING_NON)">
                                                        {{$store.getters.convertToCurrencyUs(data.GENTING_NON)}}
                                                    </td>
                                                    <td scope="col" :class="data.RATIO_GENTING_NON > data.TARGET_GENTING_NON ? 'red--text' : 'green--text'" style="text-align: right;text-decoration:underline;cursor:pointer" @click="showDetail(data, 'GENTING', 'NONE', data.RATIO_GENTING_NON)">
                                                        {{$store.getters.convertToCurrencyUs(data.RATIO_GENTING_NON)}}
                                                    </td>
                                                    <td scope="col" :class="data.RATIO_GENTING_NON > data.TARGET_GENTING_NON ? 'red--text' : 'green--text'" style="text-align: right;" @click="showDetail(data, 'GENTING', 'NONE', data.TARGET_GENTING_NON)">
                                                        {{$store.getters.convertToCurrencyUs(data.TARGET_GENTING_NON)}}
                                                    </td>
                                                    

                                                    <td scope="col" style="text-align: right;text-decoration:underline;cursor:pointer" @click="showDetail(data, 'PINTU', 'PRIME', data.PINTU_PRM)">
                                                        {{$store.getters.convertToCurrencyUs(data.PINTU_PRM)}}
                                                    </td>
                                                    <td scope="col" style="text-align: right;text-decoration:underline;cursor:pointer" @click="showDetail(data, 'PINTU', 'PRIME', data.RATIO_PINTU_PRM)">
                                                        {{$store.getters.convertToCurrencyUs(data.RATIO_PINTU_PRM)}}
                                                    </td>
                                                    <td scope="col" :class="data.RATIO_PINTU_NON > data.TARGET_PINTU_NON ? 'red--text' : 'green--text'" style="text-align: right;text-decoration:underline;cursor:pointer" @click="showDetail(data, 'PINTU', 'NONE', data.PINTU_NON)">
                                                        {{$store.getters.convertToCurrencyUs(data.PINTU_NON)}}
                                                    </td>
                                                    <td scope="col" :class="data.RATIO_PINTU_NON > data.TARGET_PINTU_NON ? 'red--text' : 'green--text'" style="text-align: right;text-decoration:underline;cursor:pointer" @click="showDetail(data, 'PINTU', 'NONE', data.RATIO_PINTU_NON)">
                                                        {{$store.getters.convertToCurrencyUs(data.RATIO_PINTU_NON)}}
                                                    </td>
                                                    <td scope="col" :class="data.RATIO_PINTU_NON > data.TARGET_PINTU_NON ? 'red--text' : 'green--text'" style="text-align: right;" @click="showDetail(data, 'PINTU', 'NONE', data.TARGET_PINTU_NON)">
                                                        {{$store.getters.convertToCurrencyUs(data.TARGET_PINTU_NON)}}
                                                    </td>
                                                    
                                                </tr>
                                                <tr>
                                                    <td scope="col" style="text-align: left;color: green;">Total</td>
                                                    <td scope="col" style="text-align: right;font-weight: 600;">{{$store.getters.convertToCurrencyUs(item.total.TOT_PRM)}}</td>
                                                    <td scope="col" style="text-align: right;font-weight: 600;">{{$store.getters.convertToCurrencyUs(item.total.RATIO_TOT_PRM)}}</td>
                                                    <td scope="col" :class="item.total.RATIO_TOT_NON > item.total.TARGET_NON ? 'red--text' : 'green--text'" style="text-align: right;font-weight: 600;">{{$store.getters.convertToCurrencyUs(item.total.TOT_NON)}}</td>
                                                    <td scope="col" :class="item.total.RATIO_TOT_NON > item.total.TARGET_NON ? 'red--text' : 'green--text'" style="text-align: right;font-weight: 600;">{{$store.getters.convertToCurrencyUs(item.total.RATIO_TOT_NON)}}</td>
                                                    <td scope="col" :class="item.total.RATIO_TOT_NON > item.total.TARGET_NON ? 'red--text' : 'green--text'" style="text-align: right;font-weight: 600;">{{$store.getters.convertToCurrencyUs(item.total.TARGET_NON)}}</td>

                                                    <td scope="col" style="text-align: right;font-weight: 600;">{{$store.getters.convertToCurrencyUs(item.total.ATAP_PRM)}}</td>
                                                    <td scope="col" style="text-align: right;font-weight: 600;">{{$store.getters.convertToCurrencyUs(item.total.RATIO_ATAP_PRM)}}</td>
                                                    <td scope="col" :class="item.total.RATIO_ATAP_NON > item.total.TARGET_ATAP_NON ? 'red--text' : 'green--text'" style="text-align: right;font-weight: 600;">{{$store.getters.convertToCurrencyUs(item.total.ATAP_NON)}}</td>
                                                    <td scope="col" :class="item.total.RATIO_ATAP_NON > item.total.TARGET_ATAP_NON ? 'red--text' : 'green--text'" style="text-align: right;font-weight: 600;">{{$store.getters.convertToCurrencyUs(item.total.RATIO_ATAP_NON)}}</td>
                                                    <td scope="col" :class="item.total.RATIO_ATAP_NON > item.total.TARGET_ATAP_NON ? 'red--text' : 'green--text'" style="text-align: right;font-weight: 600;">{{$store.getters.convertToCurrencyUs(item.total.TARGET_ATAP_NON)}}</td>

                                                    <td scope="col" style="text-align: right;font-weight: 600;">{{$store.getters.convertToCurrencyUs(item.total.TRUSS_PRM)}}</td>
                                                    <td scope="col" style="text-align: right;font-weight: 600;">{{$store.getters.convertToCurrencyUs(item.total.RATIO_TRUSS_PRM)}}</td>
                                                    <td scope="col" :class="item.total.RATIO_TRUSS_NON > item.total.TARGET_TRUSS_NON ? 'red--text' : 'green--text'" style="text-align: right;font-weight: 600;">{{$store.getters.convertToCurrencyUs(item.total.TRUSS_NON)}}</td>
                                                    <td scope="col" :class="item.total.RATIO_TRUSS_NON > item.total.TARGET_TRUSS_NON ? 'red--text' : 'green--text'" style="text-align: right;font-weight: 600;">{{$store.getters.convertToCurrencyUs(item.total.RATIO_TRUSS_NON)}}</td>
                                                    <td scope="col" :class="item.total.RATIO_TRUSS_NON > item.total.TARGET_TRUSS_NON ? 'red--text' : 'green--text'" style="text-align: right;font-weight: 600;">{{$store.getters.convertToCurrencyUs(item.total.TARGET_TRUSS_NON)}}</td>

                                                    <td scope="col" style="text-align: right;font-weight: 600;">{{$store.getters.convertToCurrencyUs(item.total.HOLLOW_PRM)}}</td>
                                                    <td scope="col" style="text-align: right;font-weight: 600;">{{$store.getters.convertToCurrencyUs(item.total.RATIO_HOLLOW_PRM)}}</td>
                                                    <td scope="col" :class="item.total.RATIO_HOLLOW_NON > item.total.TARGET_HOLLOW_NON ? 'red--text' : 'green--text'" style="text-align: right;font-weight: 600;">{{$store.getters.convertToCurrencyUs(item.total.HOLLOW_NON)}}</td>
                                                    <td scope="col" :class="item.total.RATIO_HOLLOW_NON > item.total.TARGET_HOLLOW_NON ? 'red--text' : 'green--text'" style="text-align: right;font-weight: 600;">{{$store.getters.convertToCurrencyUs(item.total.RATIO_HOLLOW_NON)}}</td>
                                                    <td scope="col" :class="item.total.RATIO_HOLLOW_NON > item.total.TARGET_HOLLOW_NON ? 'red--text' : 'green--text'" style="text-align: right;font-weight: 600;">{{$store.getters.convertToCurrencyUs(item.total.TARGET_HOLLOW_NON)}}</td>

                                                    <td scope="col" style="text-align: right;font-weight: 600;">{{$store.getters.convertToCurrencyUs(item.total.GENTING_PRM)}}</td>
                                                    <td scope="col" style="text-align: right;font-weight: 600;">{{$store.getters.convertToCurrencyUs(item.total.RATIO_GENTING_PRM)}}</td>
                                                    <td scope="col" :class="item.total.RATIO_GENTING_NON > item.total.TARGET_GENTING_NON ? 'red--text' : 'green--text'" style="text-align: right;font-weight: 600;">{{$store.getters.convertToCurrencyUs(item.total.GENTING_NON)}}</td>
                                                    <td scope="col" :class="item.total.RATIO_GENTING_NON > item.total.TARGET_GENTING_NON ? 'red--text' : 'green--text'" style="text-align: right;font-weight: 600;">{{$store.getters.convertToCurrencyUs(item.total.RATIO_GENTING_NON)}}</td>
                                                    <td scope="col" :class="item.total.RATIO_GENTING_NON > item.total.TARGET_GENTING_NON ? 'red--text' : 'green--text'" style="text-align: right;font-weight: 600;">{{$store.getters.convertToCurrencyUs(item.total.TARGET_GENTING_NON)}}</td>

                                                    <td scope="col" style="text-align: right;font-weight: 600;">{{$store.getters.convertToCurrencyUs(item.total.PINTU_PRM)}}</td>
                                                    <td scope="col" style="text-align: right;font-weight: 600;">{{$store.getters.convertToCurrencyUs(item.total.RATIO_PINTU_PRM)}}</td>
                                                    <td scope="col" :class="item.total.RATIO_PINTU_NON > item.total.TARGET_PINTU_NON ? 'red--text' : 'green--text'" style="text-align: right;font-weight: 600;">{{$store.getters.convertToCurrencyUs(item.total.PINTU_NON)}}</td>
                                                    <td scope="col" :class="item.total.RATIO_PINTU_NON > item.total.TARGET_PINTU_NON ? 'red--text' : 'green--text'" style="text-align: right;font-weight: 600;">{{$store.getters.convertToCurrencyUs(item.total.RATIO_PINTU_NON)}}</td>
                                                    <td scope="col" :class="item.total.RATIO_PINTU_NON > item.total.TARGET_PINTU_NON ? 'red--text' : 'green--text'" style="text-align: right;font-weight: 600;">{{$store.getters.convertToCurrencyUs(item.total.TARGET_PINTU_NON)}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </v-card-text>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
                <v-dialog
                    v-model="dialog"
                    max-width="1024px"
                    persistent
                >
                    <v-card class="p-4">
                        <v-card-title class="p-1">    
                            {{ detail ? detail.mill_name : '' }}  {{ detail ? detail.tahun : '' }} - {{ detail ? detail.bulan : '' }}                      
                            <v-spacer></v-spacer>
                            <v-btn
                                class="mx-2"
                                fab
                                x-small
                                color="#e83e8c;"
                                @click="close()"
                            >
                                <v-icon dark>
                                    mdi-close
                                </v-icon>
                            </v-btn>
                        </v-card-title>
                        <v-card-text>
                            <v-container fluid>
                                <v-row>
                                    <v-col cols="12">
                                        <div class="d-flex">
                                            <v-spacer></v-spacer>
                                            <v-btn 
                                                class="pa-5"
                                                small
                                                color="primary"
                                                :disabled="disabledExportPrime"
                                                v-if="type === 'PRIME'"
                                            >
                                                <download-excel
                                                    :fetch           = "exportExcelDetail"
                                                    :fields="header_field_prime"
                                                    :before-generate = "startDownload"
                                                    :before-finish   = "finishDownload">
                                                    <v-icon>
                                                        mdi-file-excel
                                                    </v-icon>
                                                    Export to Excel
                                                </download-excel>
                                            </v-btn>
                                            <v-btn 
                                                class="pa-5"
                                                small
                                                color="primary"
                                                :disabled="disabledExport"
                                                v-else
                                            >
                                            <download-excel
                                                    :fetch           = "exportExcelDetail"
                                                    :fields="header_field_none"
                                                    :before-generate = "startDownload"
                                                    :before-finish   = "finishDownload">
                                                    <v-icon>
                                                        mdi-file-excel
                                                    </v-icon>
                                                    Export to Excel
                                                </download-excel>
                                            </v-btn>
                                        </div>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-data-table
                                            dense
                                            :headers="header_details"
                                            :items="details"
                                            class="elevation-1 mt-5"
                                            :items-per-page="20"
                                            :loading="$store.state.overlay"
                                            height="400"
                                            fixed-header
                                            :divider="true"
                                            :light="true"
                                            :item-class="tr_datatable"
                                        >     
                                            <template v-slot:[`item.TOTAL`]="{ item }">
                                                {{ $store.getters.convertToCurrencyUs(item.TOTAL) }}
                                            </template>
                                            <template v-slot:[`item.length`]="{ item }">
                                                {{ $store.getters.convertToCurrencyUs(item.length) }}
                                            </template>
                                            <template v-slot:[`item.length_usage`]="{ item }">
                                                {{ $store.getters.convertToCurrencyUs(item.length_usage) }}
                                            </template>
                                            <template v-slot:[`item.ATAP`]="{ item }">
                                                {{ $store.getters.convertToCurrencyUs(item.ATAP) }}
                                            </template>
                                            <template v-slot:[`item.TRUSS`]="{ item }">
                                                {{ $store.getters.convertToCurrencyUs(item.TRUSS) }}
                                            </template>
                                            <template v-slot:[`item.HOLLOW`]="{ item }">
                                                {{ $store.getters.convertToCurrencyUs(item.HOLLOW) }}
                                            </template>
                                            <template v-slot:[`item.GENTING`]="{ item }">
                                                {{ $store.getters.convertToCurrencyUs(item.GENTING) }}
                                            </template>
                                            <template v-slot:[`item.PINTU`]="{ item }">
                                                {{ $store.getters.convertToCurrencyUs(item.PINTU) }}
                                            </template>
                                            <template v-slot:[`item.ratio_non`]="{ item }">
                                                {{ $store.getters.convertToCurrencyUs(item.ratio_non) }}
                                            </template>
                                            
                                        </v-data-table>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import {backendApi} from "../../backend-api/backend-api-sr"

export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'KKA',
                disabled: false,
                href: '/admin/sr',
                },
                {
                text: 'Prod. Performa',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
                {
                text: 'Coil Ratio',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
            ],
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            mills: [],
            mill: '',
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 7),
            date_field: new Date().toISOString().substr(0, 4),
            menu: false,
            year: new Date().toISOString().substr(0, 4),
            picker: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            sortBy: 'asc',
            sortName: '',
            headers: [
                { text: 'Mill', value: 'mill_name', sortable: false },
                { text: 'Tahun', groupable: false, value: 'tahun', sortable: false },
                { text: 'Bulan', groupable: false, value: 'bulan', sortable: false },
                { text: 'TOT_PRM', groupable: false, value: 'TOT_PRM', sortable: false },
                { text: 'RATIO_TOT_PRM', groupable: false, value: 'RATIO_TOT_PRM', sortable: false },
                { text: 'TOT_NON', groupable: false, value: 'TOT_NON', sortable: false },
                { text: 'RATIO_TOT_NON', groupable: false, value: 'RATIO_TOT_NON', sortable: false },
                { text: 'ATAP_PRM', groupable: false, value: 'ATAP_PRM', sortable: false },
                { text: 'RATIO_ATAP_PRM', groupable: false, value: 'RATIO_ATAP_PRM', sortable: false },
                { text: 'ATAP_NON', groupable: false, value: 'ATAP_NON', sortable: false },
                { text: 'RATIO_ATAP_NON', groupable: false, value: 'RATIO_ATAP_NON', sortable: false },
                { text: 'TRUSS_PRM', groupable: false, value: 'TRUSS_PRM', sortable: false },
                { text: 'RATIO_TRUSS_PRM', groupable: false, value: 'RATIO_TRUSS_PRM', sortable: false },
                { text: 'TRUSS_NON', groupable: false, value: 'TRUSS_NON', sortable: false },
                { text: 'RATIO_TRUSS_NON', groupable: false, value: 'RATIO_TRUSS_NON', sortable: false },
                { text: 'HOLLOW_PRM', groupable: false, value: 'HOLLOW_PRM', sortable: false },
                { text: 'RATIO_HOLLOW_PRM', groupable: false, value: 'RATIO_HOLLOW_PRM', sortable: false },
                { text: 'HOLLOW_NON', groupable: false, value: 'HOLLOW_NON', sortable: false },
                { text: 'RATIO_HOLLOW_NON', groupable: false, value: 'RATIO_HOLLOW_NON', sortable: false },
                { text: 'GENTING_PRM', groupable: false, value: 'GENTING_PRM', sortable: false },
                { text: 'RATIO_GENTING_PRM', groupable: false, value: 'RATIO_GENTING_PRM', sortable: false },
                { text: 'GENTING_NON', groupable: false, value: 'GENTING_NON', sortable: false },
                { text: 'RATIO_GENTING_NON', groupable: false, value: 'RATIO_GENTING_NON', sortable: false },
                { text: 'PINTU_PRM', groupable: false, value: 'PINTU_PRM', sortable: false },
                { text: 'RATIO_PINTU_PRM', groupable: false, value: 'RATIO_PINTU_PRM', sortable: false },
                { text: 'PINTU_NON', groupable: false, value: 'PINTU_NON', sortable: false },
                { text: 'RATIO_PINTU_NON', groupable: false, value: 'RATIO_PINTU_NON', sortable: false },
            ],
            coilratios:[],
            options: {},
            loading: false,
            totalItems:20,
            pagination:1,
            disabledExport: false,
            header_field: {
                'Mill' : 'mill_name',
                'Tahun' : 'tahun',
                'Bulan' : 'bulan',
                'Quality ID' : 'quality_id',
                'TOT_PRM' : 'TOT_PRM',
                'RATIO_TOT_PRM' : 'RATIO_TOT_PRM',
                'TOT_NON' : 'TOT_NON',
                'RATIO_TOT_NON' : 'RATIO_TOT_NON',
                'ATAP_PRM' : 'ATAP_PRM',
                'RATIO_ATAP_PRM' : 'RATIO_ATAP_PRM',
                'ATAP_NON' : 'ATAP_NON',
                'RATIO_ATAP_NON' : 'RATIO_ATAP_NON',
                'TRUSS_PRM' : 'TRUSS_PRM',
                'RATIO_TRUSS_PRM' : 'RATIO_TRUSS_PRM',
                'TRUSS_NON' : 'TRUSS_NON',
                'RATIO_TRUSS_NON' : 'RATIO_TRUSS_NON',
                'HOLLOW_PRM' : 'HOLLOW_PRM',
                'RATIO_HOLLOW_PRM' : 'RATIO_HOLLOW_PRM',
                'HOLLOW_NON' : 'HOLLOW_NON',
                'RATIO_HOLLOW_NON' : 'RATIO_HOLLOW_NON',
                'GENTING_PRM' : 'GENTING_PRM',
                'RATIO_GENTING_PRM' : 'RATIO_GENTING_PRM',
                'GENTING_NON' : 'GENTING_NON',
                'RATIO_GENTING_NON' : 'RATIO_GENTING_NON',
                'PINTU_PRM' : 'PINTU_PRM',
                'RATIO_PINTU_PRM' : 'RATIO_PINTU_PRM',
                'PINTU_NON' : 'PINTU_NON',
                'RATIO_PINTU_NON' : 'RATIO_PINTU_NON'
            },
            display: false,
            qualities: [],
            quality_id: '',
            details: [],
            detail: '',
            header_details: [],
            dialog: false,
            type: '',
            divisi: '',
            disabledExportPrime: false,
            header_field_prime: {
                'Mill' : 'mill_name',
                'Tahun' : 'tahun',
                'Bulan' : 'bulan',
                'Quality ID' : 'quality_id',
                'Coil ID' : 'coil_id',
                'Length' : 'length',
                'Length Usage' : 'length_usage',
                'TOTAL' : 'TOTAL',
                'ATAP' : 'ATAP',
                'TRUSS' : 'TRUSS',
                'HOLLOW' : 'HOLLOW',
                'GENTING' : 'GENTING'
            },
            header_field_none: {
                'Mill' : 'mill_name',
                'Tahun' : 'tahun',
                'Bulan' : 'bulan',
                'Quality ID' : 'quality_id',
                'Divisi' : 'divisi',
                'Category' : 'kategori',
                'Jenis' : 'jenis',
                'TOTAL' : 'TOTAL'
            }
        }
    },
    async mounted(){
        this.$store.dispatch('setOverlay', true)
        await this.getEnvConf()
        await this.getMills()
        await this.getQualityID()
        this.$store.dispatch('setOverlay', false)
    },
    methods:{
        tr_datatable(item) {
            const rowClass = 'tr_datatable'
            return rowClass;
        },
        save(date) {
            console.log(date);

            this.$refs.menu.save(date)

            // Reset activePicker to type YEAR
            this.$refs.picker.activePicker = 'YEAR'

            // Close the menu/datepicker
            this.menu = false

            this.date_field = new Date(date.substr(0, 4)).toISOString().substr(0, 4)
        },
        async getEnvConf(){
            const respVar = await backendApi.fetchCore(`/api/get_env_var_conf?entity_id=KKA&appl_id=WEBKKA&var_id=MILLID&groupid=${this.$store.state.kka.group_id}`, null, false, false, false) 

            if (respVar.data.data != null) {
                this.mill = respVar.data.data
            } else {
                this.mill = ''
            }
        },
        async getMills(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/kka/mill?mill_id=${this.mill ? this.mill : ''}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.mills = res.data.data
            });
        },
        async getQualityID(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/kka/quality_prod_spec`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.qualities = res.data
            });
        },
        sortByFunc(sortBy){
            console.log(this.sortBy, sortBy);
            this.sortBy = this.sortBy === 'desc' ? 'asc' : 'desc';
            this.sortName = sortBy
            this.search(1, 20)
        },
        async search(){
            this.loading = true
            this.$store.dispatch('setOverlay', true)

            await axios.get(`${process.env.VUE_APP_URL}/api/kka/CoilRatio?mill_id=${this.mill ? this.mill : ''}&quality_id=${this.quality_id ? this.quality_id : ''}&tahun=${this.date.substr(0, 4)}&bulan=${Number(this.date.substr(5, 6))}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                console.log(res.data);
                this.display = true
                this.loading = false
                this.$store.dispatch('setOverlay', false)

                this.coilratios = res.data
            }).catch(error => {
                console.error(error);
                this.$store.dispatch('setOverlay', false)
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: error,
                    visible: true
                };
            });
        },
        async exportExcel(){
            var url = `${process.env.VUE_APP_URL}/api/kka/CoilRatio/export-excel?mill_id=${this.mill ? this.mill : ''}&quality_id=${this.quality_id ? this.quality_id : ''}&tahun=${this.date.substr(0, 4)}&bulan=${Number(this.date.substr(5, 6))}&sort=${this.sortBy ? this.sortBy : 'desc'}&sortName=${this.sortName ? this.sortName : ''}`
            const response = await axios.get(url, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            return response.data;
        },
        startDownload(){
            this.loading = true
            this.$store.dispatch('setOverlay', true)

            this.disabledExport=true
        },
        finishDownload(){
            this.loading = false
            this.$store.dispatch('setOverlay', false)

            this.disabledExport=false
        },
        clear(){
            this.date = null
            this.mill = ''
            this.date_field = null
            this.quality_id = ''
        },
        close(){
            this.dialog = false
            this.details = []
            this.header_details = []
        },
        async showDetail(data, divisi, type, value = 1){
            if (parseFloat(value) === 0) {
                this.dialog = false
            } else {
                this.type = type
                this.divisi = divisi
                this.detail = data
                this.header_details = []
                this.details = []
                console.log(data)
                var non = 1
                var ratio_non = 1
                switch (divisi){
                    case "ALL":
                        non = data.TOT_NON
                        ratio_non = data.RATIO_TOT_NON
                    break;
                    case "ATAP":
                        non = data.ATAP_NON
                        ratio_non = data.RATIO_ATAP_NON
                    break;
                    case "TRUSS":
                        non = data.TRUSS_NON
                        ratio_non = data.RATIO_TRUSS_NON
                    break;
                    case "HOLLOW":
                        non = data.HOLLOW_NON
                        ratio_non = data.RATIO_HOLLOW_NON
                    break;
                    case "GENTING":
                        non = data.GENTING_NON
                        ratio_non = data.RATIO_GENTING_NON
                    break;
                    case "PINTU":
                        non = data.PINTU_NON
                        ratio_non = data.RATIO_PINTU_NON
                    break;
                }

                this.$store.dispatch('setOverlay', true)

                var reqBody = {
                    'mill_id': data.mill_id,
                    'tahun': data.tahun,
                    'bulan': data.bln,
                    'non': non,
                    'ratio_non': ratio_non,
                    'quality_id': this.quality_id ? this.quality_id : '',
                    'divisi': divisi === 'ALL' ? '' : divisi,
                    'type': type
                }

                if (type === 'PRIME') {
                    this.header_details = [
                        { text: 'Mill', value: 'mill_name' },
                        { text: 'Tahun', value: 'tahun' },
                        { text: 'Bulan', value: 'bulan' },
                        { text: 'Quality', value: 'quality_id' },
                        { text: 'Coil ID', value: 'coil_id' },
                        { text: 'Length', value: 'length' },
                        { text: 'Length Usage', value: 'length_usage' },
                        { text: 'TOTAL', value: 'TOTAL' },
                        { text: 'ATAP', value: 'ATAP' },
                        { text: 'TRUSS', value: 'TRUSS' },
                        { text: 'HOLLOW', value: 'HOLLOW' },
                        { text: 'GENTING', value: 'GENTING' },
                        { text: 'PINTU', value: 'PINTU' }
                    ]
                } else {
                    this.header_details = [
                        { text: 'Mill', value: 'mill_name' },
                        { text: 'Tahun', value: 'tahun' },
                        { text: 'Bulan', value: 'bulan' },
                        { text: 'Quality', value: 'quality_id' },
                        { text: 'Divisi', value: 'divisi' },
                        { text: 'Category', value: 'kategori' },
                        { text: 'Jenis', value: 'jenis' },
                        { text: 'TOTAL', value: 'TOTAL' },
                        { text: 'RATIO', value: 'ratio_non' },
                    ]
                }

                const respData = await backendApi.fetchCore('/api/kka/CoilRatio/show', reqBody, false, false, false)
                
                if (respData.status === 200) {
                    this.dialog = true
                    this.details = respData.data.data  
                    this.$store.dispatch('setOverlay', false)
                } 
            }
                
        },
        async exportExcelDetail(){
            var reqBody = {
                'mill_id': this.detail ? this.detail.mill_id : '',
                'tahun': this.detail ? this.detail.tahun : '',
                'bulan': this.detail ? this.detail.bln : '',
                'quality_id': this.quality_id ? this.quality_id : '',
                'divisi': this.divisi === 'ALL' ? '' : this.divisi,
                'type': this.type
            }

            const respData = await backendApi.fetchCore('/api/kka/CoilRatio/show', reqBody, false, false, false)
            
            if (respData.status === 200) {
                return respData.data.data
            }   
        }
    },
    watch: {
    }
}
</script>
<style>
.v-card--reveal {
    align-items: center;
    bottom: 0;
    justify-content: center;
    opacity: .9;
    position: absolute;
    width: 100%;
}
.tr_datatable{
    background-color: #F5F7F8 !important;
}
</style>